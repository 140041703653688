import utils from '../utils';

class CookieConsent {

	constructor() {
		this.consent = 0;
		this.type;
	}
	init(analytics, analyticsId) {
		this.type = analytics;

		// cookie bar consent
		$('[data-cookie-consent] .cookies-yes').on('click', () => {
			utils.setCookie('cookie-consent', 1);
			$('[data-cookie-consent]').css({ opacity: 0 });
			setTimeout(() => {
				$('[data-cookie-consent]').addClass('hide');
			}, 600);
			this.consent = 1;
			switch (this.type) {
				case 'ga':
					this.initGA(analyticsId);
					this.initHotjar();
					break;
				default:
					break;
			}
		});

		if (!utils.getCookie('cookie-consent')) {
			$('[data-cookie-consent]').removeClass('hide');
		} else {
			this.consent = 1;
			switch (this.type) {
				case 'ga':
					this.initGA(analyticsId);
					this.initHotjar();
					break;
				default:
					break;
			}
		}
	}

	initHotjar() {
		//< !--Hotjar Tracking Code for align - tool.com-- >
		(function(h,o,t,j,a,r){
			h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
			h._hjSettings={hjid:1902202,hjsv:6};
			a=o.getElementsByTagName('head')[0];
			r=o.createElement('script');r.async=1;
			r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
			a.appendChild(r);
		})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
	}
	initGA(id) {
		window.dataLayer = window.dataLayer || [];
		$(document).ready(() => {
			this.gaTrigger('js', new Date());
			this.gaTrigger('config', id);
		});
	}

	gaTrigger() {
		$(document).ready(() => {
			window.dataLayer.push(arguments);
		});
	}

	triggerAction(actionName, category, label, value) {
		if (!this.consent) {
			return false;
		}

		switch (this.type) {
			case 'ga':
				this.gaTrigger('event', actionName, {
					'event_category': category,
					'event_label': label,
					'value': value
				});
				break;
			default:
				break;
		}
	}
}

export default (new CookieConsent());