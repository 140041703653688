import fitty from 'fitty';
import SubnavActivateScroll from './tools/SubnavActivateScroll.js';
import textfit from 'textfit';
import circleProgress from 'simple-circle-progress';
import {} from './circleprogress.extension';
import {} from 'jquery-circle-progress';


export default class Country {
	constructor() {
		new SubnavActivateScroll();
		$(document).ready(() => {

			setTimeout(() => {
				$('.proxies .value-container[data-type="scale"]').each((index, el) => {
					let $el = $(el);
					let $text = $el.find('.value-text');

					let height = $el.height();

					let circle = $el.circleProgress({
						size: height,
						value: parseInt($el.find('.value-text').text()) / 5,
						thickness: 10,
						emptyThickness: 10,
						lineCap: 'butt',
						arcCoef: 0.73,
						dashesAmount: 20,
						dashOffset: 0,
						dashSize: 0.08, // in radians
						fill: { gradient: ['#fff', '#fff'] },
						emptyFill: "rgba(255, 255, 255, .2)"
					}).on('circle-animation-progress', function (event, progress, stepValue) {
						//$text.text(stepValue.toFixed(2).substr(1));
					});
					setTimeout(function () {
						textfit($text.get(0));
					});

					$(window).on('resize', () => {
						let newHeight = $el.height();
						$el.circleProgress({ size: newHeight }); // set new size and redraw
						textfit($text.get(0));
					});
				});

				fitty('.fit', {
					maxSize: 60
				});
			});

		});
	}
}