import shortAndSweet from './tools/ShortAndSweet.js';
import Tokenfield from 'tokenfield';
import queryString from 'query-string';
import QueryString from './tools/QueryString.js';
import InfiniteScroll from './tools/InfiniteScroll.js';
import cookieConsent from './tools/CookieConsent';


export default class Library {
	constructor() {
		this.$container = $('body[data-page="resource-library"]');
		this.$form = this.$container.find('form#resource-filters');

		this.qs = new QueryString();

        /**
         * Initiate and set up the configuration of infinite scroll.
         */
		const segParam = this.qs.get('page');
		const resultsContainerSel = `#resource-results`;

		let ajaxParams = queryString.parse(window.location.search, {arrayFormat: 'bracket'});
		delete ajaxParams.page;

		this.$resultsContainer = $(resultsContainerSel);
		this.$totals = this.$container.find('.total-results');
		this.$noResults = this.$container.find('.no-results');
		this.$loading = this.$container.find('.loading');
		this.segment = segParam && !isNaN(segParam) ? parseInt(segParam) : 1;


		this.scrollConfig = {
			'segment': this.segment,
			'container': resultsContainerSel,
			'autoFill': true,
			'ajaxData': ajaxParams,
			'ajaxDataType': 'html',
			'ajaxRoute': '/resource-library/fetch',
			'setLoading': (loading, segment) => {
				let qsParams = this.scrollConfig.ajaxData;
				let qs = queryString.stringify(qsParams, { skipEmptyString: true });
				qs = qs.replace('segment', 'page').replace('%20', '+');
				this.$loading.find('.button').attr('href', `?${qs}`);
				if (loading) {
					this.$loading.removeClass('hide');
				} else {
					this.$loading.addClass('hide');
				}
			},
			'onSuccess': (res, resInfo) => {
				const $cards = $(res);
				$cards.find('.button').on('click', (e) => {
					const $el = $(e.target);
					const $card = $el.parents('.card-resource:first');
					cookieConsent.triggerAction('visit', 'resource', `${$card.data('id')} - ${$card.find('h1:first').text()}`, 'success');
					return true;
				});
				this.$resultsContainer.append($cards);

				if (resInfo.totalCount == 0) {
					this.$noResults.removeClass('hide');
				} else {
					this.$noResults.addClass('hide');
				}


				$("[rel='prev']").attr('href', resInfo.linkPrev);
				$("[rel='next']").attr('href', resInfo.linkNext);

				this.$totals
					.removeClass('hide')
					.find('.count')
						.text(resInfo.totalCount);
			},
			'updateParam': (value) => {
				//this.qs.updateParam('page', value);
			}
		}
		this.infiniteScroll = new InfiniteScroll(this.scrollConfig);

		$(document).ready(() => {

			shortAndSweet('textarea', {
				counterClassName: 'counter',
				counterLabel: '<strong>{remaining}</strong> characters remaining',
				append: (el, counter) => {
					//debugger;
					el.before(counter);
				}
			});

			// ------------------------[ Sector Filter Form ]------------------------ //
			this.$form
				.submit((e) => {
					e.preventDefault();
				}).find('button.submit').on('click', () => {
					this.onInputChange();
				});

			$('.subnav-container button.reset').on('click', () => {
				window.location.search = '';
			})

			const tags = rlibData.generalTags.map(tag => { return { id: tag.key, name: tag.value } });
			const types = rlibData.types.map(tag => { return { id: tag.key, name: tag.value } });
			const commodities = rlibData.commodities.map(tag => { return { id: tag.key, name: tag.value } });
			const countries = rlibData.countries.map(tag => { return { id: tag.value, name: tag.key } });
			const countriesFullname = rlibData.countries.map(tag => { return { id: tag.key, name: tag.key } });
			const allCountries = rlibData.allCountries.map(tag => { return { id: tag.value, name: tag.key } });
			const allCommodities = rlibData.allCommodities.map(tag => { return { id: tag.key, name: tag.value } });
			const allTypes = rlibData.allTypes.map(tag => { return { id: tag.key, name: tag.value } });

			const $filterSearch = this.$form.find('.search .tf');
			this.initFilter('search',
				$filterSearch.get(0),
				$filterSearch.parents('.container:first'),
				tags.concat(types).concat(commodities).concat(countriesFullname),
				{
					selected: this.scrollConfig.ajaxData.search,
					placeholder: 'Search by keyword',
					fnOnChange: () => this.onInputChange(),
					allowNewItems: true
				}
			);

			const $filterCommodities = this.$form.find('.tf[data-id="commodities"]');
			this.initFilter(
				'commodities',
				$filterCommodities.get(0),
				$filterCommodities.parents('.filter:first'),
				commodities,
				{
					selected: this.scrollConfig.ajaxData.commodities,
					placeholder: 'Select commodity',
					fnOnChange: () => this.onInputChange()
				}
			);

			const $filterCountries = this.$form.find('.tf[data-id="countries"]');
			this.initFilter(
				'countries',
				$filterCountries.get(0),
				$filterCountries.parents('.filter:first'),
				countries,
				{
					selected: this.scrollConfig.ajaxData.countries,
					placeholder: 'Select country',
					fnOnChange: () => this.onInputChange(),
					fnMap: (tag) => {
						const country = countries.filter(item => item.id === tag)[0];
						if(!country) return {};
						return { id: tag, name: country.name }
					}
				}
			);

			const $filterTypes = this.$form.find('.tf[data-id="types"]');
			this.initFilter(
				'types',
				$filterTypes.get(0),
				$filterTypes.parents('.filter:first'),
				types,
				{
					selected: this.scrollConfig.ajaxData.types,
					placeholder: 'Select type',
					fnOnChange: () => this.onInputChange()
				}
			);

			const $formAdd = $('[data-modal="resource-add"] form');
			$formAdd.on('submit.analytics', e => {

				let title = $formAdd.find('[name="title"]').val();

				if (e.target.checkValidity()) {
					cookieConsent.triggerAction('submit', 'resource', title, 'success');
				} else {
					cookieConsent.triggerAction('submit', 'resource', title, 'validation-error');
				}

			});

			const $addType = $formAdd.find('.tf[data-id="type"]');
			this.initFilter(
				'type',
				$addType.get(0),
				$addType.parents('.filter:first'),
				types,
				{
					placeholder: 'Select type',
					multiple: false
				}
			);

			const $addCommodities = $formAdd.find('.tf[data-id="commodities"]');
			this.initFilter(
				'commodities',
				$addCommodities.get(0),
				$addCommodities.parents('.filter:first'),
				allCommodities,
				{
					placeholder: 'Select commodities'
				}
			);

			const $addCountries = $formAdd.find('.tf[data-id="countries"]');
			this.initFilter(
				'countries',
				$addCountries.get(0),
				$addCountries.parents('.filter:first'),
				allCountries,
				{
					placeholder: 'Select countries',
					fnMap: (tag) => {
						const country = countries.filter(item => item.id === tag)[0];
						if (!country) return {};
						return { id: tag, name: country.name }
					}
				}
			);


			/*const countriesItemsRemapped = typeof countriesItems === 'object' ? countriesItems.map(tag => {
				const country = countries.filter(item => item.id === tag)[0];
				return { id: tag, name: country.name }
			}) : [];*/
			//$countriesContainer.attr('data-count-items', countriesItemsRemapped.length);


			// -------------------------[ Real-time Search ]------------------------- //
			/**
			 * Set up real-time search with 0.3s delay
			 */
			/*let myTimeout;
			const input = this.$form.find('.search input').get(0);
			input.oninput = (e) => {
				clearTimeout(myTimeout);
				myTimeout = setTimeout(
					() => {
						this.onInputChange(e);
					}, 300);
			};*/
		});
	}

	setAjaxData(field, value) {
		this.scrollConfig.ajaxData[field] = value;
		console.log('set ajaxdata',this.scrollConfig.ajaxData);
	}

	initFilter(itemName, element, $container, items, options) {

		if (typeof element === 'undefined') {return;}
		if (!$container instanceof jQuery) { return; }
		if (!typeof options === 'object') {
			options = {}
		}
		if (typeof options.selected !== 'object') options.selected = [];
		if (typeof options.placeholder !== 'string') options.placeholder = '';
		if (typeof options.fnMap !== 'function') options.fnMap = tag => { return { id: tag, name: tag } };
		if (typeof options.fnOnChange !== 'function') options.fnOnChange = () => {};
		if (typeof options.allowNewItems !== 'boolean') options.allowNewItems = false;
		if (typeof options.multiple !== 'boolean') options.multiple = true;

		let selectedRemapped = [];

		if (options.selected.length) {
			selectedRemapped = options.selected.map(options.fnMap);
		}

		$container.attr('data-count-items', selectedRemapped.length);

		const tf = new Tokenfield({
			el: element,
			itemName: itemName,
			newItemName: itemName,
			items: items,
			newItems: options.allowNewItems,
			setItems: selectedRemapped,
			addItemOnBlur: true,
			multiple: true,
			maxItems: options.multiple ? 0 : 1,
			maxSuggest: 100,
			minChars: 0,
			placeholder: options.placeholder
		});

		tf.on('change', (e) => {
			const items = tf.getItems();
			let values = [];
			for (let i in items) {
				values.push(items[i].id ? items[i].id : items[i].name); //id is not set for user added queries
			}

			console.log('values', values)
			this.setAjaxData(itemName, values);
			//selected = values;

			$container.attr('data-count-items', tf.getItems().length);
			$container.attr('data-max-items', tf._options.maxItems);

			//this.$form.find('[name="search"]').val(searchString.join(' '));
			options.fnOnChange();
		}).on('removedToken', (e) => {
			setTimeout(() => {
				tf.hideSuggestions();
			}, 0);
		}).on('addedToken', () => {
			setTimeout(() => {
				//if (tf._options.maxItems === 1) {
					tf.hideSuggestions();
					tf.blur();
				//}
			}, 0);
		});
	}

	onInputChange(e) {

		this.scrollConfig.segment = this.segment = 1;

		delete this.scrollConfig.ajaxData.segment;
		let qs = queryString.stringify(this.scrollConfig.ajaxData, {arrayFormat: 'bracket'});
		//qs = qs.replace('segment', 'page');

		history.replaceState(null, null, "?" + qs.replace('%20', '+'));

		cookieConsent.triggerAction('search', 'resource-library', 'keywords', qs.replace('%20', '+'));



/* 		for (let i in this.scrollConfig.ajaxData) {
			let value = this.scrollConfig.ajaxData[i];
			console.log('stringify value', value);
			value = queryString.stringify(value, {arrayFormat: 'bracket'});
			console.log('value', value, i);
			if (value == "") {
				this.qs.removeParam(i);
			} else {
				this.qs.updateParam(i, value);
			}
		}
 */
		//this.scrollConfig.ajaxData;

		this.infiniteScroll.removeScrollLsn();
		this.$totals.addClass('hide');

		this.infiniteScroll = new InfiniteScroll(this.scrollConfig);

	}
}