import Swiper from 'swiper';

export default class Actionprocess {
	constructor() {
		// what's happening swiper
		let swiper = new Swiper('.swiper-container', {

			slidesPerView: 4,
			spaceBetween: 0,
			watchOverflow: false,
			simulateTouch: false,

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				// when window width is <= 768px
				539: {
					slidesPerView: 1
				},
				767: {
					slidesPerView: 1
				},
				// when window width is <= 992px
				991: {
					slidesPerView: 2
				},
				// when window width is <= 1160px
				1159: {
					slidesPerView: 3
				}

			}
		});
	}
}
