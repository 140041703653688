/**
 * @version 1.1
 * @author Mahmoud Al-Refaai <Schuttelaar & Partners>
 *
 * v1.1 Changes ----------
 *  - [Fix] Bind all class' function to "this" in constructor
 *  - Add ajaxDataType to config param in constructor
 *  - remove the use of setter "set attributeName()" syntax because of binding issue
 *    It is now a normal function, ie. setLockInfiniteScroll(), setSegment()
 *  - Use "segment" param instead of "page" in url query string
 */

import $ from "jquery";

export default class InfiniteScroll {

    /**
     * constructor of InfiniteScroll object
     * @param {int} config.segment          the number of segment to start on
     * @param {String} config.container     the query-string of the container (eg: "#containerId" or ".containerClass")
     * @param {Bool} config.lockInfiniteScroll   if set to true, scrolling down won't trigger the fetch function
     * @param {Bool} config.autoFill        boolean weather to keep fetching data until the page is filled
     * @param {String} config.ajaxRoute     the url-route to be used in AJAX
     * @param {String} config.ajaxData      the data to be sent to the server for fetching data
     * @param {String} config.ajaxDataType  the data-type of the response of the AJAX request
     * @param {function} config.onSuccess   call back function(res) when AJAX request succeed
     * @param {function} config.onError     call back function(err) when AJAX request failed
     * @param {function} config.updateParam call back function(key, value) to update the given uri-param key with the given value
     */
	constructor(config) {
		this.config = {
			segment: 1,
			container: '',
			lockInfiniteScroll: false,
			autoFill: false,
			ajaxRoute: '',
			ajaxData: {},
			ajaxDataType: 'json',
			onSuccess: () => { },
			onError: () => { },
			updateParam: () => { },
			setLoading: (loading, params) => { },
		};

		this.editConfig(config);
		this.addScrollLsn();
		this.fetching = false;
		this.$resultsContainer = $(this.config.container).empty();

		// fetch initial data;
		this.config.lockInfiniteScroll = true;
		this.fetchData().then((moreContent) => {
			//in case there is still more content to fetch, check again if the page is not filled.
			moreContent && this.config.autoFill && this.autoFill();
		}).catch(() => { });   //in case of error (rejected promise), ignore it!

		// Bind all class' functions to "this"
		const methods = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
		methods
			.filter(method => (method !== 'constructor'))
			.forEach((method) => { this[method] = this[method].bind(this); });
	}

	editConfig(config) {
		for (let key in config)
			this.config[key] = config[key];
	}

    /**
     * add the scroll event listener to this container
     */
	addScrollLsn() {
		$(window).on('scroll.infinitescroll', this.onScroll.bind(this));
	}

	onScroll() {
		if (!this.config.lockInfiniteScroll &&
			($(document).scrollTop() + $(window).height()) >= (this.$resultsContainer.offset().top + this.$resultsContainer.height() - 100)) {
			this.config.lockInfiniteScroll = true;
			this.fetchData(true);
		}
	}

    /**
     * remover the scroll listener from this container
     */
	removeScrollLsn() {
		$(window).off('scroll.infinitescroll');

		//$(this.config.container).unbind('scroll');
	}

    /**
     * Repeat fetching data until the page is filled (ie. scrollbar is shown).
     */
	autoFill() {
		if ($(window).height() >= $(document).height()) {
			this.fetchData(true)
				.then((moreContent) => {
					//in case there is still more content to fetch, check again if the page is not filled.
					moreContent && this.autoFill();
				})
				.catch(() => { }); //in case of error (rejected promise), ignore it!
		}
	}

    /**
     * preform ajax request through the given route in constructor
     */
	async fetchData(next) {
		if (this.fetching) return;
		this.fetching = true;

		/* need to get a key->value list of all params, so need to get this manually for now */
		let params = this.config.ajaxData;

		if (next) {
			params.segment = this.config.segment + 1;
		} else {
			params.segment = this.config.segment;
		}

		this.config.setLoading(true, params.segment);

		return new Promise((resolve, reject) => {
			$.ajax({
				url: this.config.ajaxRoute,
				method: 'GET',
				data: params,
				dataType: this.config.ajaxDataType,
				success: (res, status, XHR) => {
					this.fetching = false;
					const resInfo = {
						'totalCount': XHR.getResponseHeader('TotalCount'),
						'resultCount': XHR.getResponseHeader('ResultCount'),
						'moreAvailable': XHR.getResponseHeader('MoreAvailable'),
						'linkNext': XHR.getResponseHeader('LinkNext'),
						'linkPrev': XHR.getResponseHeader('LinkPrev')
					};

					const moreAvailable = resInfo.moreAvailable == 1;
					if ($(res).length) {

						if (next) {
							// only increment if we got any results, otherways this segment doesn't exist
							this.config.segment++;
							if (this.config.updateParam) {
								this.config.updateParam(this.config.segment);
							}
						}
						this.config.lockInfiniteScroll = !moreAvailable;
						this.config.onSuccess(res, resInfo);
						resolve(moreAvailable);
					} else {
						this.config.lockInfiniteScroll = true;
						this.config.onSuccess(res, resInfo);
						resolve(false);
					}
				},
				error: this.config.onError,
				complete: () => {
					this.config.setLoading(false);
				}
			});
		});
	}

    /**
     * @param {boolean} isLocked set to false to unlock the infinite scroll.
     */
	setLockInfiniteScroll(isLocked = true) {
		this.config.lockInfiniteScroll = isLocked;
	}

    /**
     * @param {int} segmentNo the new segment number to be set on.
     */
	setSegment(segmentNo = 1) {
		this.config.segment = segmentNo;
	}

}