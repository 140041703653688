export default class SubnavActivateScroll {
	constructor() {
		let t_scroll;
		let lastUserNav = 0;
		let $navItems = $('.subnav-container [data-id]');

		$(window).on('scroll', (e) => {
			if (t_scroll) return;
			if (new Date().getTime() - lastUserNav < 1000) {
				return false;
			}
			t_scroll = setTimeout(() => {
				let $activeSection;
				let wHeight = $(window).height();
				let closest = wHeight;
				let yMiddle = wHeight / 3;
				$('#content a[data-subnav-target]').each((index, el) => {
					let ySection = el.getBoundingClientRect().top;
					let distToMiddle = Math.abs(yMiddle - ySection);
					if (distToMiddle < closest) {
						closest = distToMiddle;
						$activeSection = $(el);
					}
				});
				if ($activeSection) {
					let $navItem = $navItems.filter('[data-id="' + $activeSection.attr('id') + '"]');
					$navItems.not($navItem).filter('.active').removeClass('active');
					$navItem.addClass('active');
				}
				t_scroll = null;

			}, 200);
		})

		/* smooth scrolling for anchors */
		function scrollTo(id) {
			let animationLength = 600;
			let $section = $('#' + id);
			let extraOffset = 30;

			if (!$section.length) return;

			let $parentExpandable = $section.parents('.expandable:first');
			if ($parentExpandable.length && (
				$parentExpandable.css('overflow') === 'hidden' ||
				$parentExpandable.css('overflow-y') === 'hidden' )
			) {
				$('[data-target-id=' + $parentExpandable.attr('data-id') + ']').click();
			}

			//section.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest" });

			// Smooth Scroll
			let headerOffset = $('body > .top-bar').outerHeight();
			let offset = headerOffset + extraOffset;
			let $navItem = $navItems.filter('[data-id="' + id + '"]');
			if ($navItem.length) {
				if (!$navItems.filter('.active').length) {
					$navItem.addClass('active');
				}

				lastUserNav = new Date().getTime();
				setTimeout(() => {
					$navItems.not($navItem).filter('.active').removeClass('active');
					$navItem.addClass('active');
				}, animationLength);
			}

			window.scroll({
				top: $section.offset().top - offset,
				left: 0,
				behavior: 'smooth'
			});
			return false;
		}

		$(document).ready(() => {

			setTimeout(() => {
				const id = window.location.hash.replace('#', '');
				if (id.length) {
					scrollTo(id);
				}
			});

		});
		$(window).on('hashchange', (e) => {
			const id = window.location.hash.replace('#', '');
			e.preventDefault();
			//window.scrollTo(0,0);
			if (id.length) {
				scrollTo(id);
			}
		});

		$('a[href^="#"]').on('click', (e) => {
			let $el = $(e.currentTarget);
			let id = $el.attr('href').replace('#', '');
			if (!id.length) return;
			e.preventDefault();
			history.replaceState(null, null, '#' + id);
			scrollTo(id);
		});
	}
}