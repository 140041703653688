export default {
	processForms: () => {
		// form validation

		// 1. Disable native validation UI with `noValidate`
		// 2. On submit, run evaluation and prevent if necessary
		const $forms = $('form');

		$forms.each((i, el) => {
			let form = el;
			let $form = $(form);

			form.noValidate = true;
			form.onsubmit = evt => {
				if (!form.checkValidity()) {
					evt.preventDefault();
					$form.find('.invalid:first').focus();
				}

				const $sweetField = $form.find('input.this-is-sweet');
				if (!$sweetField.val()) {
					$sweetField.val('v' + (Math.random() * 42));
				}
			}

			// Iterate over fields in form
			$form.find('input, textarea, select').each((i, field) => {
				let $field = $(field);

				// Add error container
				let $error = $('<div class="error invisible"></div>');
				$field.not('[type="hidden"], [type="checkbox"], [type="radio"]').after($error);

				if ($field.has('[type="checkbox"]')) {
					$field.parents('.field-container:first').append($error);
				}

				// Show message on `invalid` event
				$field.on('invalid', () => {
			  		$field.addClass('invalid');
			    	$error.text(field.validationMessage);
			    	$error.removeClass('invisible');

			    	// Reset invalid state & error message on `input` event, trigger validation check
				    const inputHandler = () => {
					  	$field.off('input');
				      	$error.text('');
				      	$error.addClass('invisible');
				      	$field.removeClass('invalid');
				      	field.checkValidity();
				    }
				    $field.on('input', inputHandler);
			  	});
				field.addEventListener("blur", function() {
				  field.checkValidity();
				});
			});
		});
	}
}
