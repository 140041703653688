import toggleExpand from './toggleExpand';

export default class SidelineElements {

	constructor($parent, excludeParentSelector) {
		if (typeof pimcore !== 'undefined') return false;

		let $contentContainer = $('.content-container');
		if (!$contentContainer.length) return;

		this.contentContainerOffset = $('.content-container').offset().left;
		this.contentContainerWidth = $('.content-container').outerWidth();

		$parent.find('.container-sideline').remove();

		this.sidelineContainers = {};

		let sidelineCount = $('.container-sideline').length;

		$parent.find('a[href], abbr[data-title], img[data-title]').each((index, el) => {
			let $el = $(el);
			if (!$el.attr('data-title') && !$el.attr('href')) return;

			if (excludeParentSelector && $el.parents(excludeParentSelector).length) {
				return true;
			}
			let $parentExpandable = $el.parents('.expandable:first');
			if ($parentExpandable.length && $parentExpandable.css('overflow-y') === 'hidden') {
				return true;
			}
			let $parentTabContent = $el.parents('.tab-content:first');
			if ($parentTabContent.length && $parentTabContent.css('overflow-y') === 'hidden') {
				return true;
			}

			if ($el.parent('a').length) {
				// already processed with the parent;
				return;
			}

			let isImage = $el.is('img');

			let isLink = $el.is('[href]');
			let isExternalLink = isLink && new RegExp(/^https?:\/\//).test($el.attr('href'));
			let isPdf = isLink && new RegExp(/\.pdf$/).test($el.attr('href'));

			let item = {};

			switch (true) {
				case isLink && $el.children('abbr').length:
					item = {
						type: 'definition',
						link: $el.attr('href'),
						text: $el.find('abbr').attr('data-title'),
						tippy: $el.find('abbr').data('tippy')
					}
					break;
				case $el.is('abbr'):
					item = {
						type: 'definition',
						text: $el.attr('data-title') ? $el.attr('data-title') : $el.text(),
						tippy: $el.data('tippy')
					}
					break;
				case isImage:
					item = {
						type: 'definition',
						text: $el.attr('data-title'),
						tippy: $el.data('tippy')
					}

					break;
				case isLink && isPdf:
					item = {
						type: 'document',
						link: $el.attr('href'),
						linkName: $el.attr('title')
					}
					break;
				case isExternalLink:
					item = {
						type: 'external',
						link: $el.attr('href'),
						linkName: $el.attr('title')
					}
					break;
				default:
					return true;
			}

			let titleHtml;
			if (isPdf && new RegExp(/^\//).test(item.link)) {
				item.linkName = item.link.replace(/^.*\/(.+?)$/, '$1');
				titleHtml = `<a href="${item.link}" target="_blank">${item.linkName}</a>`;
			} else if (item.link) {
				if (!item.linkName) {
					item.linkName = item.link.replace(/^(https?:\/\/)?(www\.)?|\/.*$/g, '');
				}
				titleHtml = `<a href="${item.link}" target="_blank">${item.linkName}</a>`;
			} else {
				titleHtml = item.text;
			}

			let y = el.getBoundingClientRect().top;
			let $item = $(`
				<div data-type="${item.type}" class="item ${item.type}">
					<header>
						<div class="icon"></div>
						<h2>${titleHtml}</h2>
					</header>
					<section class="content">
					${item.text ? `<div class="text">${item.text}</div>` : ''}
					</section>
				</div>`);

			let $containerSideline;
			if (this.sidelineContainers[y]) {
				$containerSideline = this.sidelineContainers[y];
			} else {
				sidelineCount++;
				$containerSideline = this.sidelineContainers[y] = $(`
					<div class="container-sideline" data-target-id="sideline-${sidelineCount}" data-expand-toggle>
						<div class="expandable" data-id="sideline-${sidelineCount}">
							<div>
								<header class="main"></header>
								<div class="items"></div>
							</div>
						</div>
					</div>`);
				$containerSideline.hover((e) => {
					toggleExpand(e, false);
					//$containerSideline.toggleClass('expanded');
				});
				$el.before($containerSideline);
				this.positionContainer($containerSideline);

			}
			$item.hover(() => {
				$el.toggleClass('highlight');
			});
			if (item.tippy) {
				$item.on('click', () => {
					item.tippy.show();
				});
				$item.on('mouseout', () => {
					item.tippy.hide();
				});
			}

			$containerSideline.find('.items').append($item);
			$containerSideline.find('header.main').append(`<div data-type="${item.type}" class="icon"></div>`);


		});
		let lastContainer = [];
		for (let yPos in this.sidelineContainers) {
			let $container = this.sidelineContainers[yPos];
			let itemCount = $container.find('.item').length;
			//$container.css({ width: `${itemCount * 1.5}em`});
			if (itemCount > 1) $container.attr('data-extra-items', '+' + (itemCount - 1));
			if (lastContainer.length) {
				let lastYPos = lastContainer[0] + parseInt(lastContainer[1].css('margin-top'));
				if (yPos - lastYPos < 30) {
					lastContainer[1].addClass('small');
					$container.addClass('small');
					//$container.css({'margin-top' : '15px'});
				}
			}
			lastContainer = [yPos, $container];
		}
		setTimeout(() => {
			$('.container-sideline').css('opacity', 1);
		}, 200);

		$(window).on('resize', () => {
			this.repositionContainers();
		});
	}

	positionContainer($containerSideline) {
		// calc current offset compared to .content-container;
		let containerOffset = $containerSideline.offset().left;
		let offsetLeft = containerOffset - this.contentContainerOffset;
		let existingCssMargin = parseInt($containerSideline.css('margin-left'));
		$containerSideline.css('margin-left', `${existingCssMargin-offsetLeft + this.contentContainerWidth}px`);
	}

	repositionContainers() {
		this.contentContainerOffset = $('.content-container').offset().left;
		this.contentContainerWidth = $('.content-container').outerWidth();
		for (let i in this.sidelineContainers) {
			let $sidelineContainer = this.sidelineContainers[i];
			this.positionContainer($sidelineContainer);
		};
	}
}