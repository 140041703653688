import '../scss/index.scss';

import jQuery from 'jquery';
import utils from './utils';
import cookieConsent from './tools/CookieConsent';

import {} from  'featherlight';
import featherlightGallery from 'featherlight/release/featherlight.gallery.min.js';
import stickyfill from 'stickyfilljs';
import smoothscroll from 'smoothscroll-polyfill';

import tippy, { inlinePositioning } from 'tippy.js';
import Popper from 'popper.js';
import screenDetector from './screen-detector'; // initializes screensize etc.
import toggleExpand from './tools/toggleExpand';
import SidelineElements from './tools/SidelineElements';
import Slider from './tools/Slider';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import dompurify from 'dompurify';

const urlParams = utils.getQueryStringParams(window.location.search);
const login = urlParams.login;

import './nav.js';
import SourceMap from './page-sourcemap.js';
import Country from './page-country.js';
import Actionprocess from './page-actionprocess.js';
import Step from './page-step.js';
import Library from './page-library.js';

import formValidator from './form-validator.js';
import modals from './modals.js';

const page = $('body').data('page');


if (typeof window.pimcore !== 'undefined') {
	//editmode
	$('.top-bar').hide(); /* hide the header */
}

const sidelineExcludedContainerSelectors = 'section.happening, #footNotes, .swiper, [data-image-caption]';
cookieConsent.init('ga', 'UA-139643514-2');


$(document).ready(() => {

	stickyfill.add($('.sticky'));
	smoothscroll.polyfill();

	modals.initModals();
	formValidator.processForms();

	const params = new URLSearchParams(window.location.search);
	const modalRef = params.get('showModal');
	if (modalRef) {
		modals.show(modalRef);
	}

	$('.bar.newsletter-subsribe .t_close').on('click', (e) => {
		$('.bar.newsletter-subsribe').hide();
		$('body').removeClass('subsription-bar-visible');
		utils.setCookie('newsletter_subsribed', 1);
	});

	$('[data-trigger-modal="help"]').on('click', e => {
		cookieConsent.triggerAction('cta', 'help', 'needs-help', 'open');
	});


	setTimeout(() => {
		$('a[href]').on('click', (e)=> {
			const $el = $(e.target);
			const url = $el.attr('href');
			const host = new URL(url).host;
			if (host !== (location.host)) {
				// track outgoing link click
				cookieConsent.triggerAction('visit', 'link', url, 'success');
			}
			return true;
		});
	}, 0);

	$('[data-modal="help"] form').on('submit.analytics', e => {
		if (e.target.checkValidity()) {
			cookieConsent.triggerAction('submit', 'help', 'needs-help', 'success');
		} else {
			cookieConsent.triggerAction('submit', 'help', 'needs-help', 'validation-error');
		}
	});

	$('[data-action]').each((i, el) => {
		$(el).on('click', (e) => {
			let params = el.getAttribute('data-action');
			params = params.split('|');
			cookieConsent.triggerAction(params[0], [params[1]], params[2], params[3]);
		});
	});


	jQuery.featherlight.prototype.afterContent = function () {
		let $caption = this.$currentTarget.parent().find('.caption').clone();
		if (!$caption.text().replace(/^\s*$/, '').length) return

		$caption.appendTo(this.$instance.find('.featherlight-content'));
		setTimeout(() => {
			this.$instance.find('.featherlight-content').css('padding-bottom', $caption.outerHeight() + 'px');
		});
	};

	//printPdf('#content');

	// swipers

	$('.area.resources .swiper, section.happening .swiper, .swiper.proxies').each((index, el) => {
		new Slider(el);
	});


	// open external links in new window
	$('.content-container').find('a[href ^= "http://"], a[href ^= "https://"]').attr('target', '_blank');

	let $footNotes = $('section.footnotes li');

	if ($footNotes.length) {
		let $footNoteList = $('<ol>');
		let references = [];
		let index = 0;
		$footNotes.each((i, el) => {
			let $footNote = $(el);
			let footNote = $footNote.find('cite').html();
			let $links = $footNote.find('sup a');
			$links.each((j, el) => {
				let existingIndex = references.indexOf(footNote);
				if (existingIndex === -1) {
					$footNote.appendTo($footNoteList);
				}

				let $link = $(el);
				let $reference = $($link.attr('href'));
				let text = $reference.text();
				console.log(footNote);
				console.log(el, $footNote)

				if (existingIndex !== -1) {
					console.log('existing index', existingIndex);
					let $existingFootnote = $footNoteList.find('li:nth-child(' + (existingIndex + 1) + ')');
					//$link.href = `#${$reference.attr('id')}`;
					let text = $reference.text();
					$reference.text(text.replace(/\d+/, existingIndex + 1));
					$reference.attr('href', `#${$existingFootnote.attr('id')}`);

				} else {
					console.log('new index', index);
					$reference.text(text.replace(/\d+/, index + 1));
					references.push(footNote);
					index++;

				}
			});
		})
		$('section.footnotes').remove();
		$('#footNotes').append($footNoteList).removeClass('hide');
		$('[data-target-id="footnotes"]').removeClass('hide');
	} else {
		$('footer.footnotes-bottom').remove();
	}

	$('[rel="footnote"]').each((i, el) => {
		let tippyInst = tippy(el, {
			content: $('#footNotes').find(el.getAttribute('href')).find('cite').html(),
			animation: 'scale-subtle',
			theme: 'light',
			interactive: 'true',
			appendTo: document.body,
			inlinePositioning: true,
			popperOptions: {
				positionFixed: true,
			}
		}, [inlinePositioning]);
		$(el).data('tippy', tippyInst);
	});

	$('abbr[title], img[title], [data-definition]').each((i, el) => {

		const $definition = $(el);
		let title = $definition.attr('title') ? $definition.attr('title') : $definition.find('.tooltip-content:first');
		if (!title || !title.length) {
			console.log('title not set', title, $definition);
			return true;
		}
		//$definition.data('title', $definition.attr('title')); // get title attribute value
		$definition.data('title', title); // add title attribute value to data-title attribute (when previous line had no success)
		$definition.removeAttr('title'); // remove the title attribute, removing browser tooltip

		let tippyInst = tippy(el, {
			content: title instanceof jQuery ? title.html() : title,
			animation: 'scale-subtle',
			theme: 'light',
			interactive: 'true',
			appendTo: document.body,
			inlinePositioning: true,
			allowHTML: true,
			popperOptions: {
				positionFixed: true
			}
			//showOnCreate: true
		}, [inlinePositioning]);
		$(el).data('tippy', tippyInst);
	});


	$('#sidebar .block h3').on('click', (e) => {
		let $blocks = $('#sidebar .block');
		let $block = $(e.currentTarget).parents('.block:first');

		let state = $block.attr('data-state')
		$block.attr('data-state', state == 'expanded' ? 'collapsed' : 'expanded');
		//$blocks.not($block).attr('data-state', 'collapsed');
	});

	$('[data-expand-toggle]').on('click', (e) => {
		let $trigger = $(e.currentTarget);
		if ($trigger.parents('.container-sideline').length) {
			toggleExpand(e, false);
		} else {
			toggleExpand(e, false, () => {
				const $container = $('.expandable[data-id="' + $trigger.data('target-id') + '"]');
				if ($trigger.is('.toggle-expand-link')) {
					$trigger.remove();
				}
				//new SidelineElements($container, sidelineExcludedContainerSelectors);

				/*$container.find('.swiper').each((index, el) => {
					new Slider(el);
				});*/
			});
		}
	});

	//new SidelineElements($('.content-container'), sidelineExcludedContainerSelectors);

	$('.tabs-container').each((index, el) => {
		let $cont = $(el);
		let $content = $cont.find('.tab-content');
		let $tabs = $cont.find('.tabs .tab');
		$tabs.on('click', (e) => {
			let $tab = $(e.currentTarget);
			$tabs.not(`[data-id="${$tab.data('id')}"]`).removeClass('active');
			$tab.addClass('active');
			utils.setCookie('action-focus', $tab.data('id'));

			$content.not(`[data-id="${$tab.data('id')}"]`).removeClass('active');
			let $tabActive = $content.filter(`[data-id="${$tab.data('id')}"]`).addClass('active');
			new SidelineElements($tabActive, sidelineExcludedContainerSelectors);
		});
		if (!$tabs.filter('.active').length) {
			let cookie = utils.getCookie('action-focus');
			if (cookie) {
				$tabs.filter(`[data-id="${cookie}"]`).click();
			} else {
				$tabs.first().click();
			}
		}
	});
});

switch (page) {
	case 'source-map':
		new SourceMap();
		break;
	case 'country':
		new Country();
		break;
	case 'action-process-step':
		new Step();
		break;
	case 'commodity':
		new Country();
		break;
	case 'action-process':
		new Actionprocess();
		break;
	case 'resource-library':
		new Library();
		break;
}

function printPdf(selector) {

	const $container = $(selector).clone();
	$container.find('.swiper').remove();
	console.log($container);
	let pdf = new jsPDF({ orientation: 'p', format: 'a4', unit: 'mm' });
	//pdf.setFontSize(8);
	let srcwidth = document.querySelector(selector).scrollWidth;


	pdf.html($container.html(), {
		callback: (pdf) => {
/*			const totalPages = pdf.internal.getNumberOfPages();
 			for (let i = totalPages; i >= 1; i--) { //make this page, the current page we are currently working on.
				pdf.setPage(i);

				//header(pdf);
				//footer(pdf, i, totalPages);

			} */
			pdf.output('save', 'pdfdownload.pdf');

		},
		x: 10, // x coord
		y: 10,
		margin: [15, 20, 10, 10],
		html2canvas: {
			scale: 600 / srcwidth
		}
	});
}
