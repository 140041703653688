import worldGeojson from '../other/ne_50m_admin_0_countries.geo.json';
import L from 'leaflet';
import ScreenDetector from './screen-detector';

class SourceMap {

	constructor() {

		if (!$('body.source-map').length) return;
		this.$map = $('#map');
		this.$countries = this.$map.find('#countries .country');
		this.countries = countries;
		this.activeCountryLayer;
		this.filters = {
			commodities: []
		}
		this.geojson = {};
		this.map;
		this.closeTimeout;

		this.$map.find('.button-filters').add('#sidebar .close').on('click', (e) => {
			$('body').toggleClass('filters-active');
		});

		window.onhashchange = function (e) {
			if (!window.location.hash) {
				if (this.activeCountryLayer) {
					this.resetHighlight(this.activeCountryLayer);
				}
			} else {
				this.showFeature(window.location.hash);
			}
			//showMapPopup(window.location.hash);
		}.bind(this);

		this.map = L.map('map', {
			minZoom: 1,
			maxZoom: 10,
			scrollWheelZoom: false,
			zoomSnap: false,
			zoomControl: false,
			renderer: L.svg({ padding: 15 }) //needed for panning, will pre-render more of the map
		}).fitWorld().setZoom(2.5);

		L.control.zoom({ position: 'bottomright' }).addTo(this.map);

		/*this.map.on('resize zoomstart movestart', (e) => {
			this.map.closePopup();
			this.resetHighlight(this.activeCountryLayer);
		});*/
		this.map.on('mousedown', (e) => {
			window.location.hash = '';
		});
		this.map.on('click', ((e) => {
			this.closeTimeout = setTimeout(() => {
				window.location.hash = '';
			}, 200);
		}).bind(this));
		this.map.on('move zoom', ((e) => {
			clearTimeout(this.closeTimeout);
		}).bind(this));

		this.featureStyle = {
			stroke: true,
			weight: 1,
			color: '#fff',
			//dashArray: '3',
			fill: true,
			fillColor: '#D3D3D3',
			fillOpacity: 1
		}

		this.geojson = L.geoJson(worldGeojson, {
			clickable: true,
			style: this.featureStyle,
			onEachFeature: this.initCountry.bind(this)
		}).addTo(this.map);

		$('#sidebar .commodities input[name="commodity"]').on('change', function (e) {
			let $option = $(e.currentTarget);
			let value = $option.val();
			let activeFilterKey = this.filters.commodities.indexOf(value);

			//$('#sidebar .commodities input[type="checkbox"]').not($option).prop('checked', false);


			this.resetFilters('commodities');
			if (value === '') {
				console.log('no value');
				this.updateQueryString();
				this.filterCountries();
				// do nothing.
			} else if ($option.is(':checked') && activeFilterKey === -1) {
				this.changeFilters('commodities', 'add', value);
			} else if (activeFilterKey !== -1) {
				this.changeFilters('commodities', 'remove', value);
			}
		}.bind(this));

		$('#sidebar .countries .country a').on('click', function (e) {
			let countryCode = $(e.target).parents('li:first').data('code');
			e.preventDefault();

			this.resetFilters();
			window.history.pushState(null, null, `${window.location.pathname}#${countryCode}`);
			console.log('hash', window.location.hash, countryCode);
			window.dispatchEvent(new HashChangeEvent("hashchange"));

			if (ScreenDetector.isSmallScreen()) {
				$('body').toggleClass('filters-active');
			}

			return false;
		}.bind(this));



		$(document).ready((() => {
			let hash = window.location.hash.replace('#', '');
			if (hash) {
				this.showFeature(hash);
				//showMapPopup(window.location.hash);
				// activate popup
			}
		}).bind(this));

	}

	/*$map.on('click doubleclick touchstart wheel gestureend', e => {
		console.log('event');
		window.location.hash = '';
	});*/

	//console.log('c', countries);



	filterMapCountries() {
		let filteredLayers = [];
		let countryGroup = new L.FeatureGroup();

		//let latLngBounds = [];
		for(let countryCode in this.countries) {
			let layer = this.geojson.getLayer(countryCode); //your feature id here
			let result = this.initCountry(layer.feature, layer, true);
			if (result) {
				filteredLayers.push(result);
				countryGroup.addLayer(result);
			}
		}
		if (!filteredLayers.length) return;
		const mapSize = this.map.getSize();
		this.map.flyToBounds(countryGroup.getBounds(), {
			duration: 0.6,
			minZoom: 5,
			maxZoom: 5,
			paddingTopLeft: L.point(mapSize.x * 0.2, mapSize.y * 0.2),
			paddingBottomRight: L.point(mapSize.x * 0.2, mapSize.y * 0.2)
		});
		//let bounds = L.latLngBounds.apply(this, latLngBounds);

	}

	buildRequestString() {
		let query = [];
		for(let i in this.filters) {
			let filter = this.filters[i].join(',');
			query.push(encodeURIComponent(i) + '=' + encodeURIComponent(filter));
		}
		return query.join('&');
	}

	resetFilters() {
		let filtersReset = false;
		for (let filter in this.filters) {
			if (this.filters[filter].length) {
				this.filters[filter] = [];
				filtersReset = true;
			}
		}
		if (filtersReset) {
			this.filterCountries();
		}
	}

	resetFilters(type) {
		this.filters[type] = [];
	}

	changeFilters(type, action, value) {
		switch(action) {
			case('add'):
				this.filters[type].push(value);
			break;
			case('remove'):
				this.filters[type] = this.filters[type].filter((el) => {
					return el !== value;
				});
			break;
		}
		this.updateQueryString();
		this.filterCountries();
	}

	updateQueryString() {
		let qs = this.buildRequestString();
		let hash = window.location.hash;
		window.history.pushState(null, null, `${window.location.pathname}?${qs}${hash}`);

	}

	filterCountries() {
		let filtersActive = false;
		for (let countryCode in this.countries) {
			let country = this.countries[countryCode];
			country.active = true;
			for (let type in this.filters) {
				let filter = this.filters[type];
				if (filter.length) filtersActive = true;
				for (let i in filter) {
					let value = filter[i];
					if (!country[type] || !country[type].includes(value)) {
						country.active = false;
					}
				}
			}
		}
		if (filtersActive && this.activeCountryLayer) {
			window.location.hash = '';
			this.resetHighlight(this.activeCountryLayer);
		}

		this.filterMapCountries();
	}

	showFeature(countryCode) {
		countryCode = countryCode.replace('#', '').toUpperCase();
		var layer = this.geojson.getLayer(countryCode); //your feature id here

		if (!layer) return false;

		if (this.activeCountryLayer && this.activeCountryLayer != layer) {
			//map.closePopup();
			this.resetHighlight(this.activeCountryLayer);
		}
		$('main').attr('data-country-active', countryCode);
		//$('.filter.countries .country.active').not('[data-code="' + countryCode + '"]').removeClass('active');
		$('.filter.countries .country[data-code="' + countryCode + '"]').addClass('active');

		this.activeCountryLayer = layer;

		var bounds = layer.getBounds();
		var lngDifference = bounds._northEast.lng - bounds._southWest.lng;
		var rightBoundary = bounds._northEast.lng + lngDifference;

		var desiredBounds = L.latLngBounds(bounds._southWest, L.latLng({ lat: bounds._northEast.lat, lng: rightBoundary }));
		var mapSize = this.map.getSize();

		let duration = 0.8;
		let maxZoom = 5.5;

		if (bounds.overlaps(this.map.getBounds()) && maxZoom - this.map.getZoom() < 3) {
			duration = 0.4;
		} else if (bounds.getCenter().distanceTo(this.map.getBounds().getCenter()) > 3000000 || maxZoom - this.map.getZoom() > 4) {
			duration = 1.5;
		}

		this.map.closePopup();
		this.map.flyToBounds(bounds, {
			duration: duration,
			minZoom: 5,
			maxZoom: maxZoom,
			paddingTopLeft: L.point(mapSize.x * 0.15, mapSize.y * 0.15),
			paddingBottomRight: L.point(mapSize.x * 0.6, mapSize.y * 0.4)
		});
		setTimeout((() => {
			this.openPopup(layer);
			this.highlightFeature(layer);
		}).bind(this), duration*1000);
	}

	openPopup(layer) {
		var data = this.countries[layer._leaflet_id];
		var bounds = layer.getBounds();
		var mapSize = this.map.getSize();

		let $popup = $('#templates [data-template="balloon"]').clone();
		let $proxyInitiatives = $('#templates [data-template="proxy"]').clone();
		let $proxyBenchmark = $('#templates [data-template="proxy"]').clone();
		let $proxies = $('');
		let proxies = data.proxies;

		console.log('pr', proxies);

		$popup.find('.link')
			.attr('href', data.link);

		if (proxies.initiatives) {
			$proxyInitiatives.attr('data-type', 'initiatives');
			$proxyInitiatives.find('.summary .value-text').text(proxies.initiatives.count);
			$proxyInitiatives.find('.summary .label').text(proxies.initiatives.label);
			$proxyInitiatives.find('.detail .text').text(proxies.initiatives.explanation);
			$proxyInitiatives.find('.icon-container').remove();
			$proxies = $proxies.add($proxyInitiatives);
		}

		if (proxies.benchmark) {
			$proxyBenchmark.attr('data-type', 'benchmark');
			$proxyBenchmark.find('.summary .value-text').text(proxies.benchmark.count);
			$proxyBenchmark.find('.summary .label').text(proxies.benchmark.label);
			$proxyBenchmark.find('.detail .text').text(proxies.benchmark.explanation);
			$proxyBenchmark.find('.icon-container').remove();
			$proxies = $proxies.add($proxyBenchmark);
		}

		$proxies.appendTo($popup.find('.proxies'));
		$popup
			.find('[data-name]').text(data.name);

		let pane = this.map.createPane('fixed', document.getElementById('map'));

		var popup = L.popup({
			autoPan: true,
			//maxWidth: mapSize.x/2.2,
			maxWidth: 500,
			maxHeight: mapSize.y/1.1,
			offset: L.point(0,0)
		})
			.setLatLng(bounds.getNorthEast())
			.setContent($popup.get(0).outerHTML)
			.openOn(this.map);

		//layer.bindPopup('<div>hello world</div>');
		//layer.openPopup();
		//map.off('moveend');
	}

	initCountry(feature, layer, update) {
		let countryCode = feature.properties.ISO_A2;
		let country = this.countries[countryCode];
		if (!update) {
			layer._leaflet_id = countryCode;
		}
		if (country && country.active) {
			layer.setStyle({ fillColor: '#A4A4A4' })
			layer.on({
				mouseover: (() => { this.highlightFeature(layer) }).bind(this),
				mouseout: (() => {
					if (!this.activeCountryLayer || layer != this.activeCountryLayer) {
						this.resetHighlight(layer, false);
					}
				}).bind(this),
				click: () => {
					let query = this.buildRequestString();
					window.history.pushState(null, null, `${window.location.pathname}?${query}#${countryCode}`);
					window.dispatchEvent(new HashChangeEvent("hashchange"));

					//window.location.hash = countryCode;
					return false;
				}
			});
			return layer;
		} else if (update) {
			this.geojson.resetStyle(layer);
			layer.off();
			return null;
		}
	}

	highlightFeature (layer) {
		let countryCode = layer.feature.properties.ISO_A2;

		if (this.countries[countryCode]) {
			layer.setStyle({
				fillColor: '#267459',
			});
		}

		if (!L.Browser.ie && !L.Browser.opera) {
			layer.bringToFront();
		}
		//info.update(layer.feature.properties);
	}

	resetHighlight(layer, closePopup) {
		if (typeof closePopup === 'undefined') closePopup = true;
		let countryCode = layer.feature.properties.ISO_A2;
		if (this.countries[countryCode]) {
			layer.setStyle({
				fillColor: '#A4A4A4'
			});
		}
		if (closePopup) {
			this.map.closePopup();
			this.activeCountryLayer = null;
			$('main').attr('data-country-active', '');
			$('.filter.countries .country.active').removeClass('active');
		}


		//geojson.resetStyle(e.target);
		//info.update();
	}

};

export default SourceMap;