import $ from 'jquery';
class CircleProgressExtension {
	constructor() {
		$(document).ready(() => {
			/**
			* Modify Circle Progress Library to display arcs
			*/
			$.circleProgress.defaults.arcCoef = 0.7; // range: 0..1
			$.circleProgress.defaults.startAngle = 0.5 * Math.PI;
			$.circleProgress.defaults.dashesAmount = 10; // no das,
			$.circleProgress.defaults.dashSize = 0.03; // in radians
			$.circleProgress.defaults.dashOffset = 0.5; // start draw first dash

			$.circleProgress.defaults.drawArc = function (v) {
				var ctx = this.ctx,
					r = this.radius,
					c = this.arcCoef,
					a = this.startAngle + (1 - c) * Math.PI,
					t = this.getThickness();

				v = Math.max(0, Math.min(1, v));

				ctx.save();
				ctx.beginPath();

				if (!this.reverse) {
					ctx.arc(r, r, r - t / 2, a, a + 2 * c * Math.PI * v);
				} else {
					ctx.arc(r, r, r - t / 2, a + 2 * c * Math.PI, a + 2 * c * (1 - v) * Math.PI, a);
				}

				ctx.lineWidth = t;
				ctx.lineCap = this.lineCap;
				ctx.strokeStyle = this.arcFill;
				ctx.stroke();
				ctx.restore();

				this.addDashes();
			};

			/**
			* Modify Circle Progress Library to dashes (required for arc)
			*/
			$.circleProgress.defaults.addDashes = function () {
				var ctx = this.ctx,
					r = this.radius,
					t = this.getThickness(),
					d = this.dashSize,
					c = this.arcCoef,
					a = this.startAngle + (1 - c) * Math.PI,
					n = this.dashesAmount,
					o = this.dashOffset;

				ctx.save();
				ctx.lineCap = 'butt';
				ctx.lineWidth = t;
				ctx.strokeStyle = '#fff';

				// not very cross-browser option that makes the "dashes" transparent
				// if it will not work, remove this line - "dashes" will be white
				ctx.globalCompositeOperation = "destination-out";

				for (var i = 0; i < n; i++) {
					ctx.beginPath();
					var s = a + 2 * c * (1 - (i + o) / n) * Math.PI;
					ctx.arc(r, r, r - t / 2, s - d / 2, s + d / 2);
					ctx.stroke();
				}

				ctx.restore();
			};

			/**
			* Separate thickness for the "empty" arc.
			*/
			$.circleProgress.defaults.getEmptyThickness = function () {
				return typeof this.emptyThickness != 'undefined' ? this.emptyThickness : this.getThickness();
			};

			/**
			* Modify Circle Progress Library to display the empty area for an arc.
			*/
			$.circleProgress.defaults.drawEmptyArc = function (v) {
				var ctx = this.ctx,
					r = this.radius,
					t = this.getThickness(),
					et = this.getEmptyThickness(),
					c = this.arcCoef,
					a = this.startAngle + (1 - c) * Math.PI;

				v = Math.max(0, Math.min(1, v));

				if (v < 1) {
					ctx.save();
					ctx.beginPath();

					if (v <= 0) {
						ctx.arc(r, r, r - t / 2, a, a + 2 * c * Math.PI);
					} else {
						if (!this.reverse) {
							ctx.arc(r, r, r - t / 2, a + 2 * c * Math.PI * v, a + 2 * c * Math.PI);
						} else {
							ctx.arc(r, r, r - t / 2, a, a + 2 * c * (1 - v) * Math.PI);
						}
					}

					ctx.lineWidth = et;
					ctx.lineCap = this.lineCap;
					ctx.strokeStyle = this.emptyFill;
					ctx.stroke();
					ctx.restore();
				}
			};
		});
	}
}

export default new CircleProgressExtension($)